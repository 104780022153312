import _ from "lodash";
import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useLocation  } from "react-router-dom";
import { AnimatePresence } from "framer-motion"

import "bootstrap/dist/css/bootstrap.min.css";
import "video-react/dist/video-react.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

import "./css/main.scss";

import Intro from "./components/intro";
import Navigation from "./components/navigation";
import Category from "./components/category";
import Project from "./components/project";
import { useState } from "react";

import siteData from "./content/site_data.json";

global._ = _;

export default function App() {

    const location = useLocation();
    const [category, setCategory] = useState();
    const [project, setProject] = useState();
    
    useEffect(() => {
        setCategory(location.pathname.split("/category/")[1] ?? location.pathname.split("/categories/")[1]?.split("/")[0]);
        setProject(location.pathname.split("/categories/")[1]?.split("/projects/")[1]);
    }, [location]);

    return (
        <>
            <AnimatePresence exitBeforeEnter={true}>

                <Switch location={location} key={location.pathname}>

                    <Route path={"/intro"}>

                        <Intro />

                    </Route>

                    <Route path={"/categories/:category_id/projects/:project_id"}>

                        <Project data={siteData} />

                    </Route>

                    <Route path={"/category/:category_id"}>

                        <Category data={siteData} />

                    </Route>

                    <Route path={"/"}>

                        <Intro />

                    </Route>

                    <Route path={"*"}>

                        <h1>test</h1>

                    </Route>

                </Switch>

            </AnimatePresence>

            {category &&
                <Navigation data={siteData} category_id={category} project_id={project} />
            }

        </>
    );
}
