import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Video from "./Video";

// import ScrollContainer from "./scrollContainer";

//import siteData from "../content/site_data.json";

export default function Category({data}) {

    let { category_id } = useParams();

    const category = _.find(data.categories, { id: category_id });
    const content = category.content;

    const [player, setPlayer] = useState(null);
    const [showPrompt, setShowPrompt] = useState(false);

    const renderContent = () => {

        return content.main.map((item, index) => {
            return <motion.p
                className={"text-gray"}
                key={`${index}`}
            >
                {item.content}
            </motion.p>;
        });
    };

    const dispose = () => {
		player.dispose();
	}

    const pauseVideo = () => {
		if(player){
			player.pause();
		    setTimeout(() => {
			    dispose();
            }, 650);
		}
	}

    const playerReady = (player) => {
		
		setPlayer(player);
		
		// player.on('canplay', () => {
		// 	player.play();
		// });

        player.on('ended', () => {
            setShowPrompt(true);
        });

		/*player.on('dispose', () => {
			console.log('player dispose');
		});*/
	}

    const onAnimStart = () => {
        pauseVideo();
    };

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.5
            }
        }
    };

    const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    };

    function reverseFix() {
        const reverseFix = document.querySelector('.category')
        reverseFix.scrollTop = -reverseFix.scrollHeight
    }

    useEffect(() => {
        reverseFix()
        addEventListener('resize', reverseFix)

        return () => {
            removeEventListener('resize', reverseFix)
        }
    }, [])

    return (
        <motion.div className="category flex-grow-1 d-flex"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onAnimationStart={onAnimStart}
        >

            <div className="bg-dark-gray column-left">
                <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                >

                    <motion.h2 className={"text-yellow mb-4"} variants={item}>
                        {content.heading}
                    </motion.h2>

                    <motion.div variants={item}>
                        {renderContent()}
                    </motion.div>

                    {content.prompt &&
                        <motion.p className={"prompt text-white"} variants={item}>
                            {content.prompt}
                        </motion.p>
                    }
                </motion.div>
            </div>

            {category.id === "all-markets" && 
                <div className="bg-black map-container-static">
                    <img onLoad={reverseFix} src="/images/map-no-background-new.svg" alt="" />
                </div>
            }

            {category.id !== "all-markets" && 
                <div className="bg-black video-container">
                    {showPrompt &&
                        <motion.div className="video-prompt-wrapper" variants={item}>
                            <motion.div className="video-prompt" variants={item}>
                                Explore our signature projects below
                            </motion.div>
                        </motion.div>
                    }

                    <Video onReady={playerReady} autoPlay options={{
                        sources: [{
                            // src: `https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/d-project-showcase/1080/${category.video_url.split('/')[category.video_url.split('/').length-1]}`,
                            src: `https://stream.mux.com/${category.mux_id}.m3u8`,
                            type: 'application/x-mpegURL'
                        }]
                    }} />
                </div>
            }

            {/* <Container fluid className={"flex-grow-1"}>
                <Row className={"main-row h-100"}>

                    <Col lg={12} xl={3} className={"bg-dark-gray column-left"}>

                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                        >

                            <motion.h2 className={"text-yellow mb-4"} variants={item}>
                                {content.heading}
                            </motion.h2>

                            <ScrollContainer
                                className={"h-100"}
                                style={{
                                    height: 400
                                }}
                            >

                                <motion.div variants={item}>
                                    {renderContent()}
                                </motion.div>

                            </ScrollContainer>

                            <If condition={content.prompt}>

                                <motion.p className={"prompt text-white"} variants={item}>
                                    {content.prompt}
                                </motion.p>

                            </If>

                        </motion.div>

                    </Col>

                    <Choose>
                        <When condition={category.id === "all-markets"}>

                            <Col lg={12} xl className={"bg-black map-container-static"}>
                                <img src="/images/map-no-background.svg" width="2678" height="1836" alt="" />
                            </Col>

                        </When>
                        <Otherwise>

                            <Col className={"bg-black video-container"}>

                                <If condition={showPrompt}>

                                    <motion.div className="video-prompt-wrapper" variants={item}>
                                        <motion.div className="video-prompt" variants={item}>
                                            Explore our signature projects below
                                        </motion.div>
                                    </motion.div>

                                </If>

                                <Video onReady={playerReady} options={{
									sources: [{
										src: `https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/d-project-showcase/1080/${category.video_url.split('/')[category.video_url.split('/').length-1]}`,
										type: 'video/mp4'
									}]
								}} />

                            </Col>

                        </Otherwise>
                    </Choose>

                </Row>

            </Container> */}

        </motion.div>
    );
}
