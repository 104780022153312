import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { FreeMode, Scrollbar, Mousewheel } from "swiper";

export default function ScrollContainer(props) {
    return (
        <>
            {/* This cant be a swiper, blocks scrolling on mobile. */}
            {/* <Swiper
                direction={"vertical"}
                slidesPerView={"auto"}
                freeMode={true}
                scrollbar={true}
                mousewheel={true}
                modules={[FreeMode, Scrollbar, Mousewheel]}
                className="mySwiperScrollContainer"
            >
                <SwiperSlide className={"text-white"}>

                    

                </SwiperSlide>
            </Swiper> */}
            <div className="scroller">
                { props.children }
            </div>
        </>
    );
}
