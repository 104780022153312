import React, { useState } from "react";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation as SwiperNav } from "swiper";
import { motion } from "framer-motion";

import { ReactComponent as HouseIconSVG } from "../../src/images/svg/house.svg";

// import siteData from "../content/site_data.json";

import 'swiper/css/navigation';

export default function Navigation({data, category_id, project_id}) {

    const category = _.find(data.categories, { id: category_id });
    const projects = _.map(category.projects, (projectId) => {
        return _.find(data.projects, { id: projectId });
    });

    const renderNavItems = () => {

        const navItems = data.navigation;

        return navItems.map((navItem, index) => {

            const isActiveCategory = category_id === navItem.id;

            if (navItem.visible) {

                return (

                    <Nav.Item
                        key={`nav-item-${index}`}
                    >

                        <If condition={isActiveCategory}>
                            <div className={"d-flex justify-content-center w-100"}>
                                <div className="active-item-indicator">
                                </div>
                            </div>
                        </If>

                        <div>
                            <Nav.Link as={Link}
                                className={`nav-link text-nowrap nav-link ${ isActiveCategory ? "active" : ""}`}
                                to={navItem.href}
                                data-rr-ui-event-key={navItem.id}
                                eventKey={navItem.id}
                            >
                                {navItem.label}
                            </Nav.Link>
                        </div>

                    </Nav.Item>
                );
            }

        });
    };

    const getActiveNavBarKey = () => {

        // console.log(`getActiveNavBarKey: ${category_id}`);

        return `${category_id}`;
    };

    const renderProjects = () => {

        return projects.map((p, index) => {

            // -- tmp only
            let imageUrlPrefix = "https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/d-project-showcase/IMAGES/RESIZED/";

            const isActiveProject = project_id === p.id;

            return (
                <SwiperSlide
                    className={`project-nav-item ${ isActiveProject ? "active" : ""}`}
                    key={`project-nav-item-${index}`}
                >

                    <div className="s-container h-100">

                        <Link
                            to={`/categories/${category_id}/projects/${p.id}`}
                        >

                            <div className="row no-gutters h-100">
                                <div className="col-3 bg-light">

                                    <div
                                        className={"project-thumb h-100"}
                                        style={{
                                            backgroundImage: `url(${ imageUrlPrefix }${ p.thumbUrl })`
                                        }}
                                    >

                                    </div>

                                </div>
                                <div className="col ml-2">

                                    <h6 className={"project-name m-0 text-dark"}>
                                        {p.name}
                                    </h6>

                                    <small className={"text-black-50"}>
                                        {p.location}
                                    </small>

                                </div>
                            </div>

                        </Link>

                    </div>

                </SwiperSlide>
            );
        });
    };

    const [expanded, setExpanded] = useState(false)

    function handleExpanded(exp) {
        if (!exp) {
            setTimeout(() => {
                setExpanded(false)
            }, 310)
        } else {
            setExpanded(true)
        }
    }

    return (

        <motion.div className={"bg-white"}>

            <div className="category-nav-wrapper" style={{paddingTop: expanded && '56px'}}>
                <Navbar onToggle={handleExpanded} collapseOnSelect expand="xl" className={`navbar-theme-red ${expanded ? 'fixed-bottom' : ''}`}>
                    <Link className="navbar-home-icon" to="/intro">
                        <HouseIconSVG className="home-icon" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav
                            className="w-100"
                            activeKey={getActiveNavBarKey()}
                            defaultActiveKey="/category/all-markets"
                            justify
                        >
                            {renderNavItems()}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

            </div>

            <div className="project-nav-wrapper">

                <Choose>
                    <When condition={category_id === "all-markets"}>

                        <div className="all-markets-text text-center w-100">Quality Built Into Every Project</div>

                    </When>
                    <Otherwise>

                        <Swiper
                            modules={[SwiperNav]}
                            navigation
                            className="mySwiper h-100"
                            spaceBetween={0}
                            slidesPerView={"auto"}
                        >

                            {renderProjects()}

                        </Swiper>

                    </Otherwise>
                </Choose>

            </div>

        </motion.div>
    );
}
