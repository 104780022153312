import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import _ from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";

import ScrollContainer from "./scrollContainer";
import ProjectMiniMap from "./maps/projectMiniMap";

import { ReactComponent as BackButtonSVG } from "../../src/images/svg/back.svg";
import { ReactComponent as NextButtonSVG } from "../../src/images/svg/next.svg";

// import siteData from "../content/site_data.json";

export default function Project({data}) {

    let {
        category_id,
        project_id
    } = useParams();

    const category = _.find(data.categories, { id: category_id });
    const project = _.find(data.projects, { id: project_id });

    //the test project code needs to useRef here because it was gernerating random content multiple times.
    const content = project.content;
    const imageUrls = _.map(project.imageUrls, (imageUrl) => {
        return `https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/d-project-showcase/IMAGES/RESIZED/${imageUrl}`;
    });

    const [imgsLoaded, setImgsLoaded] = useState(false);
    const [showImgs, setShowImgs] = useState(false);
    const isMounted = useRef(false);

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3
            }
        }
    };

    const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    };

    const renderContent = () => {

        const renderBullets = (bullets) => {
            return bullets.map((bullet) => {
                return <li className={"text-gray"} key={Math.random()}>
                    {bullet.content}
                </li>;
            });
        };

        return content.main.map((item, index) => {

            return <div key={`${index}`}>

                <motion.p className={"text-gray"} variants={item}>

                    {item.content}

                </motion.p>

                <If condition={item.bullets}>

                    <ul>
                        {renderBullets(item.bullets)}
                    </ul>

                </If>

            </div>;
        });
    };

    const renderImages = () => {

        let result = null;

        switch (imageUrls.length) {

            case 1:

                result = <Container fluid className="h-100">
                    <Row className={"imageRow h-100"}>
                        <motion.div className="bg-light col" variants={item} style={{
                            backgroundImage: `url(${imageUrls[0]})`
                        }}>
                        </motion.div>
                    </Row>
                </Container>;

                break;

            case 2:

                result = <Container fluid className="h-100">
                    <Row className={"imageRow h-50"}>
                        <motion.div className="bg-light col" variants={item} style={{
                            backgroundImage: `url(${imageUrls[0]})`
                        }}>
                        </motion.div>
                    </Row>
                    <Row className={"imageRow h-50"}>
                        <motion.div className="bg-light col mt-1" variants={item} style={{
                            backgroundImage: `url(${imageUrls[1]})`
                        }}>
                        </motion.div>
                    </Row>
                </Container>;

                break;

            case 3:

                result = <Container fluid className="h-100">
                    <Row className={"imageRow h-63"}>
                        <motion.div className="mb-1 bg-light col" variants={item} style={{
                            backgroundImage: `url(${imageUrls[0]})`
                        }}>
                        </motion.div>
                    </Row>
                    <Row className={"imageRow h-36"}>
                        <motion.div className="bg-light col" variants={item} style={{
                            backgroundImage: `url(${imageUrls[1]})`
                        }}>
                        </motion.div>
                        <motion.div className="ml-1 bg-light col" variants={item} style={{
                            backgroundImage: `url(${imageUrls[2]})`
                        }}>
                        </motion.div>
                    </Row>
                </Container>;

                break;

            default:
                break;
        }

        return result;
    };

    const getPrevProjectHref = () => {

        let projectId = null;
        const index = _.indexOf(category.projects, project.id);

        // Current is first project in category
        if (index === 0) {
            projectId = _.last(category.projects);
        }
        else {
            projectId = category.projects[index - 1];
        }

        return `/categories/${category_id}/projects/${projectId}`;
    };

    const getNextProjectHref = () => {

        let projectId = null;
        const index = _.indexOf(category.projects, project.id);

        // Current is last project in category
        if (index === category.projects.length - 1) {
            projectId = _.first(category.projects);
        }
        else {
            projectId = category.projects[index + 1];
        }

        return `/categories/${category_id}/projects/${projectId}`;
    };

    const loadImgs = (srcs) => {

        return new Promise((resolve, reject) => {

            let numImgsLoaded = 0;

            srcs.forEach(src => {

                let img = new Image();

                const onLoad = () => {
                    img.removeEventListener("load", onLoad);
                    img.removeEventListener("error", onError);
                    setImgsLoaded(true);
                    numImgsLoaded++;
                };

                const onError = (event) => {
                    img.removeEventListener("load", onLoad);
                    img.removeEventListener("error", onError);
                    reject(event);
                };

                img.addEventListener("load", onLoad);
                img.addEventListener("error", onError);
                img.src = src;
            });

            if (numImgsLoaded === srcs.length) {
                resolve();
            }
        });
    };

    useLayoutEffect(() => {

        if (!isMounted.current) {

            isMounted.current = true;

            (async () => {
                loadImgs(imageUrls);
            })();
        }
    }, []);

    function reverseFix() {
        const reverseFix = document.querySelector('.project-page-wrapper')
        reverseFix.scrollTop = -reverseFix.scrollHeight
    }

    useEffect(() => {
        reverseFix()
        addEventListener('resize', reverseFix)

        return () => {
            removeEventListener('resize', reverseFix)
        }
    }, [])

    return (
        <motion.div
            className="project-page-wrapper d-flex flex-grow-1 bg-black"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onAnimationComplete={() => setShowImgs(true)}
        >

            <motion.div className="bg-dark-gray column-left"
                variants={container}
                initial={"hidden"}
                animate={"show"}
                exit={"hidden"}
            >
                <motion.h2 className={"text-yellow category-header"} variants={item}>
                    {category.name}
                </motion.h2>

                <motion.h3 className={"text-red project-name-header"} variants={item}>
                    {project.name}
                </motion.h3>

                <motion.h6 className={"text-white project-subheader"} variants={item}>
                    {project.location}<br />
                    {project.dates}
                </motion.h6>

                <ScrollContainer>

                    <motion.div variants={item}>
                        {renderContent()}
                    </motion.div>

                </ScrollContainer>

                <motion.div className={"miniMap"} variants={item}>

                    <ProjectMiniMap project={project} />

                    <motion.div variants={item} className="pagination">
                        <Link
                            className={"btn-project-back-next"}
                            to={getPrevProjectHref()}
                        >
                            <BackButtonSVG className={"d-block mx-auto"} />
                        </Link>

                        <Link
                            className={"btn-project-back-next ml-2"}
                            to={getNextProjectHref()}
                        >
                            <NextButtonSVG className={"d-block mx-auto"} />
                        </Link>
                    </motion.div>

                </motion.div>

                
            </motion.div>

            <div className="image-container">

                {imgsLoaded && showImgs &&
                    <motion.div className="text-white bg-white h-100"
                        variants={container}
                        initial={"hidden"}
                        animate={"show"}
                        exit={"hidden"}
                    >
                        {renderImages()}

                    </motion.div>
                }
            </div>

            {/* <Container fluid className={"flex-grow-1"}>
                <Row className={"main-row h-100"}>

                    <motion.div className={"bg-dark-gray h-100 col column-left"}
                        variants={container}
                        initial={"hidden"}
                        animate={"show"}
                        exit={"hidden"}
                    >

                        <motion.h2 className={"text-yellow category-header"} variants={item}>
                            {category.name}
                        </motion.h2>

                        <motion.h3 className={"text-red project-name-header"} variants={item}>
                            {project.name}
                        </motion.h3>

                        <motion.h6 className={"text-white project-subheader"} variants={item}>
                            {project.location}<br />
                            {project.dates}
                        </motion.h6>

                        <ScrollContainer>

                            <motion.div variants={item}>
                                {renderContent()}
                            </motion.div>

                        </ScrollContainer>

                        <motion.div className={"miniMap"} variants={item}>

                            <ProjectMiniMap
                                project={project}
                            />

                        </motion.div>

                        <motion.div variants={item} className="pagination">
                            <Link
                                className={"btn-project-back-next"}
                                to={getPrevProjectHref()}
                            >
                                <BackButtonSVG
                                    className={"d-block mx-auto"}
                                />
                            </Link>

                            <Link
                                className={"btn-project-back-next"}
                                to={getNextProjectHref()}
                            >
                                <NextButtonSVG
                                    className={"d-block mx-auto"}
                                />
                            </Link>
                        </motion.div>

                    </motion.div>

                    {imgsLoaded && showImgs &&
                        <motion.div className={"text-white bg-white col"}
                            variants={container}
                            initial={"hidden"}
                            animate={"show"}
                            exit={"hidden"}
                        >

                            {renderImages()}

                        </motion.div>
                    }

                </Row>
            </Container> */}

        </motion.div>
    );
}
