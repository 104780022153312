import { React, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Video from "./Video";


export default function Intro() {

    const [introShown, setIntroShown] = useState(false);

    const history = useHistory();

    const handleScreenClick = () => {
        history.push("/category/all-markets");
    };

    return (
        <div className={"h-100 bg-black"}>

            <Video autoPlay options={{
                sources: [{
                    src: 'https://stream.mux.com/301IHv01GjXxZupTmvHXaxWWuqLqrUjMSDN802dXtRC2q4.m3u8',
                    type: 'application/x-mpegURL'
                }]
            }} />

            <div style={{
                top: 0,
                right: 0,
                position: "absolute",
                width: "100%",
                height: "100%"
            }}>

                <Container
                    fluid
                    className={"h-100"}
                    onClick={handleScreenClick}
                >
                    <Row className={"h-100"}>
                        <Col className={"h-100 text-center d-flex align-items-center justify-content-center"}>

                            {/* <If condition={introShown}> */}

                            <Link
                                className={`btn-intro ${introShown ? "opacity-100" : "opacity-0"}`}
                                to={"/category/all-markets"} /*onClick={dispose}*/
                            >
                                Touch Screen To Explore
                            </Link>

                            {/* </If> */}

                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    );
}
